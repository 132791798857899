import { useEffect, useState } from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import UserInformationInput from "./components/UserInformationInput";
import Header from "./components/Header";
import { getData } from "./ApiCalls/DataApis";
import { apiLink } from "./ApiCalls/ApisVariables";

const stripePromise = loadStripe('pk_live_cv1o2of6G7RjXFImxVlKnzSD00evGTXFFa');

function App() {
  const [elementOptions, setElementOptions] = useState({
    appearance: {
      theme:  'stripe',
      labels: 'floating',
    },
  })

  useEffect(() => {
    getData(apiLink + '/v1/initial?source=senseai_onboarding')
    .then(async response => {
        // JSON data parsed by response.json() call
        if(response.ok ){
        const data = await response.json();
            
        }
    })
  }, [])


  return (
    <div className='bg-white scrollbar-hide'>
    <Header />
      <div className='flex w-full scrollbar-hide'>
      {elementOptions && 
          <Elements stripe={stripePromise} options={elementOptions} >
            <UserInformationInput />
          </Elements>
      }
      </div>
    </div>
  );
}

export default App;
