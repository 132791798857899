import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import OptimizeImage from './OptimizeImage';

function MachineInfoInputs({operation, index, removePartOperationInput, handleChangePartOperations}) {
    const [isDark, setIsDark] = useState(false)
    const [machineList, setMachineList] = useState([])
    const [filteredMachineList, setFilteredMachineList] = useState([])
    const [machineSearchText, setMachineSearchText] = useState('')
    const [showMachineList, setShowMachineList] = useState(false)
    const [selectedMachine, setSelectedMachine] = useState([])

    var sequence = index + 1

    useEffect(() => {
        if(machineSearchText && machineSearchText.length > 0){
            setShowMachineList(true)
            
          // Get list of filtered machiens machines
          getData(apiLink + `/v1/machines?q=${machineSearchText}`)
          .then(async response => {
            const data = await response.json();
            if(response.ok ){
              if(data.data){
                setFilteredMachineList(data.data)
              }
            }
          });
    
          // setFilteredMachineList(filtered)
        }else{
          setFilteredMachineList(machineList)
        }
    
      },[machineList, machineSearchText])

      const handleChangeMachineMake = (e) => {
          handleChangePartOperations(index, e, 'machine_make')
          setMachineSearchText(e.target.value)
        }
        
        const handleSelectMachine = (machine) => {
            if(machine === 'NewMachine'){
                setSelectedMachine([])
                setShowMachineList(false)
            }else{
                // console.log('machine', machine)
                setSelectedMachine(machine)
                handleChangePartOperations(index, machine.manufacturer_name, 'machine_make_database')
                handleChangePartOperations(index, machine.machine_name, 'machine_model_database')
                handleChangePartOperations(index, machine.machine_uuid, 'machine_uuid')
                setShowMachineList(false)
            }
        }
        
        

  return (
    <div className={`${index % 2 === 0 && !isDark &&'bg-white'} px-4 border-y-[0px] py-2 border-dashed border-gray-200`}>
        <div className='flex items-center'>
            <p className='text-base text-gray-600 font-normal mr-2 mb-auto mt-1'>
                {sequence}.
            </p>
            <div className='flex w-full space-x-2 relative'>
                {Object.keys(filteredMachineList).length > 0 && showMachineList &&
                    <div className={`${isDark?'bg-slate-900 ':'bg-slate-100 '} w-full flex flex-col absolute p-0.5 z-50 shadow-iotflows-lg inset-x-0 top-10 rounded-lg max-h-[220px] overflow-y-auto overflow-x-hidden scrollbar-hide`}   >
                        <div className={`w-full h-10 cursor-pointer p-2  ${isDark?'hover:bg-slate-800 ':'hover:bg-slate-200'} rounded`} onClick={() => handleSelectMachine('NewMachine')}>
                            <div className={`flex ${isDark?'text-blue-400':'text-blue-600'}`} >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                                </svg>
                                <div className='text-sm pl-2 font-medium '>
                                    Machine Not Shown
                                </div>
                            </div>
                        </div>
                        {filteredMachineList.map((machine, index) => (
                            <div className={`${isDark?'hover:bg-slate-800':'hover:bg-slate-200'} w-full flex cursor-pointer p-2 rounded `} key={machine.machine_uuid} onClick={() => handleSelectMachine(machine)}>
                                <div className='w-10'>
                                <OptimizeImage alt={machine.machine_name} source={machine.machine_picture_url}  size='medium' />
                                </div>
                                <div className='flex flex-col mx-2 justify-between truncate' >
                                    <div className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-base font-medium truncate `} >
                                    <span>{machine.machine_name}</span>
                                    {/* {machine.machine_name}{' - '}<span className='text-sm font-normal'><i>{machine.machine_identifier}</i></span> */}
                                    </div>
                                    <div className={`${isDark?'text-gray-400 ':' text-gray-500 '} text-xs font-light italic`} >
                                    {machine.manufacturer_name}
                                    </div>
                                </div>
                                <div className='ml-auto mt-auto w-7' >
                                    <img
                                    alt={machine.manufacturer_name}
                                    src={machine.manufacturer_logo_url}
                                    className='w-7 h-7 rounded-full'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                }
                <div>
                    <Avatar
                        alt="M"
                        src={selectedMachine.machine_picture_url}
                        sx={{ width: 32, height: 32 }}
                    >
                        M
                    </Avatar>
                </div>
                <div className='flex w-full '>
                    <input 
                    className={`block bg-transparent w-full border rounded-md py-1 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                        type={'text'}
                        name={'machine_make'}
                        placeholder={`Mazak`}
                        value={operation.machine_make || ''}
                        // onChange={(e) => handleChangePartOperations(index, e, 'machine_make')}
                        onChange={handleChangeMachineMake}
                    />
                </div>
                <div className='w-full flex'> 
                    <input 
                    className={`block bg-transparent w-full border rounded-md py-1 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                        type={'text'}
                        name={'machine_model'}
                        placeholder={`VC-Ez 16`}
                        value={operation.machine_model || ''}
                        onChange={(e) => handleChangePartOperations(index, e,'machine_model')}
                    />
                </div>
                <div className='w-full flex'> 
                    <input 
                    className={`block bg-transparent w-full border rounded-md py-1 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                        type={'text'}
                        name={'machine_identifier'}
                        placeholder={`SN123456`}
                        value={operation.machine_identifier || ''}
                        onChange={(e) => handleChangePartOperations(index, e,'machine_identifier')}
                    />
                </div>
                <div className='flex flex-col w-full'>
                    <input 
                    className={`block bg-transparent w-full border rounded-md py-1 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                        type={'text'}
                        name={'machine_department'}
                        placeholder={`Machine Shop`}
                        value={operation.machine_department || ''}
                        onChange={(e) => handleChangePartOperations(index, e, 'machine_department')}
                    />
                </div>
                {/* <div className='cursor-pointer items-center my-auto' onClick={() => removePartOperationInput(index)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5 ${isDark?'text-gray-300 hover:text-gray-500':'text-gray-600 hover:text-gray-900'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div> */}
            </div>
        </div>
        
    </div>
  )
}

export default MachineInfoInputs