import React, { useEffect, useState } from 'react'


const loadImage = (setImageDimensions, imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
  
    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width
      });
    };
    img.onerror = (err) => {
      console.log("img error");
      // console.error(err);
    };
  };

function OptimizeImage({alt, source, size}) {
    const [imageDimensions, setImageDimensions] = useState({});
    const [imageWidth, setImageWidth] = useState(false);
    const [imageHeight, setImageHeight] = useState(false);

    useEffect(() => {
        loadImage(setImageDimensions, source);
    }, [])

    useEffect(() => {
        if(imageDimensions.height > imageDimensions.width){
            setImageHeight(true)
            setImageWidth(false)
        }else{
            setImageHeight(false)
            setImageWidth(true)
        }

    }, [imageDimensions])

   

  return (
    <div className='my-auto'>
        {Object.keys(imageDimensions).length > 0 && imageHeight && 
            <img
                alt={alt}
                src={source}
                className={`${size == 'small' &&'h-8'} ${size == 'medium' &&'h-10'} ${size == 'large' && 'h-12'} ${size == 'xl' && 'h-16'} rounded-full my-auto`}
            />
        }
        {Object.keys(imageDimensions).length > 0 && imageWidth && 
            <img
                alt={alt}
                src={source}
                className={`${size == 'small' &&'w-8'} ${size == 'medium' &&'w-10'} ${size == 'large' && 'w-12'} ${size == 'xl' && 'w-16'} rounded-full my-auto`}
            />
        }
    </div>
  )
}

export default OptimizeImage