import IoTFlowsLogoBlackFont from "../images/IoTFlowsLogoBlackFont.png";

function Header() {
  return (
    <div>
        <div className={`shadow-sm border-b bg-white dark:bg-slate-900 dark:border-slate-700 sticky top-0 z-50`}>
            <header className="bg-white flex dark:bg-slate-900 items-center py-3">
                <div className='px-6 py-1'>
                  <img src={IoTFlowsLogoBlackFont} className='w-28' alt='IoTFlows' />
              </div>
              <div className="flex ml-auto items-center justify-end space-x-4 mr-8">
                <a className='text-sm text-blue-600 font-medium px-2 hover:text-blue-400' href="mailto:support@iotflows.com">
                    Support
                </a>
              </div>
            </header>
        </div>
    </div>
  )
}

export default Header